@import '../../../styles/variables';

header {
  position: fixed;
  z-index: 2;
  width: 100%;
  transform: translateY(0);
  transition: all 1s $cubic;
  align-items: center;
  display: flex;
  padding-top: 2.25rem;
  @media #{$max-l} {
    padding-top: 3.5rem;
  }
  @media #{$max-m} {
    padding-top: 2.5rem;
  }
  @media #{$max-s} {
    padding-top: 1.5rem;
  }

  @media #{$max-xs} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &.about-page {
    @media #{$max-xs} {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
  .brand {
    font-size: 1.375rem;
  }
  .inner {
    justify-content: space-between;
    nav {
      display: flex;
      flex-direction: column;
      font-size: 1.375rem;
      .nav-link {
        padding-bottom: 0.6rem;
      }
    }
    .mobileNav {
      cursor: pointer;
      z-index: 2;
      .mobileOpen {
        width: 23px;
        height: 23px;
        position: relative;
        transform-origin: center;
        transition: transform 0.5s $cubic-out;
        background-color: $grey-1;
        border-radius: 50%;
        transition: background-color 0.5s $cubic-out, transform 0.5s $cubic-out;
        &:hover {
          background-color: $grey-2;
          &::before,
          &::after {
            background-color: $grey-1;
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          background-color: $grey-2;
          transform-origin: center;
          transition: background-color 0.5s $cubic-out;
        }
        &::after {
          transform: translateX(-5px);
          width: 11px;
          height: 1px;
          top: 11px;
          left: 11px;
        }
        &::before {
          width: 1px;
          height: 11px;
          top: 11px;
          left: 11px;
          transform: translateY(-5px);
        }
      }
    }
    .mobileMenu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black;
      height: 100vh;
      width: 100vw;
      padding: 1rem;
      z-index: 1;
      transform: translateX(-100%);
      transition: transform 0.6s $cubic-out, opacity 0.5s $cubic-out;
      opacity: 0.5;
    }
  }
  &.mobileIsOpened {
    .mobileOpen {
      transform: rotate(45deg);
    }
    .mobileMenu {
      transform: translateX(0);
      opacity: 0.98;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .pageNav {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 9.4vh;
        .navItems {
          .item {
            font-size: 2.127rem;
            padding-top: 0.6rem;
          }
        }
        .socialNav {
          padding-top: 3rem;
          .item {
            font-size: 1.575rem;
            line-height: 2.313rem;
          }
        }
      }

      .brand {
        font-size: 1.375rem;
      }
    }
  }
}
