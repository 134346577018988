@use "variables" as *;

/**
  Form
  ===================================================================
*/

.form {
    width: 100%;
    .form-inner {
        width: 100%;
    }
    .form-row {
        position: relative;
    }
    label,
    legend,
    .radio-title {
        font-weight: $light;
        font-size: 1rem;
        margin-right: 4.063rem;
        @media #{$max-xs} {
            margin-right: 1rem;
        }
    }
    legend {
        padding-bottom: 0.75rem;
    }
    .form-control {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 2.8rem;
        &.withRadio {
            flex-direction: row;
            align-items: flex-end;
            border-bottom: 1px solid $grey-1;
            padding-bottom: 0.5rem;
            &.noPadding {
                margin-bottom: 0.5rem;
            }
        }
        &.withColumns {
            flex-direction: row;
            .col-1,
            .col-2 {
                flex: 0 1 50%;
            }
            .col-2 {
                transition: opacity .5s $cubic, transform .8s $cubic;
                opacity: 0;
                transform: translateX(50%);
                
            }
            .showCol2 {
                opacity: 1;
                transform: translateX(0);
            }
        }

        label {
            color: $grey-1;
            padding-top: 0.75rem;
            transition: transform 600ms $cubic-out;
            &.radio {
                display: flex;
                align-content: center;
                line-height: 0.9rem;
                span {
                    align-self: flex-end;
                    margin-right: 0.5rem;
                    color: $grey-1;
                    &.left {
                        margin-right: 0rem;
                        margin-left: 0.5rem;
                        line-height: 1.2;
                    }
                }
            }
        }
        input,
        textarea {
            font-weight: $medium;
            color: $grey-1;
            padding-top: 0.95rem;
            font-size: 1rem;
            letter-spacing: 1px;
            font-weight: $light;
            &::placeholder {
                opacity: 1;

                transition: opacity 600ms $cubic-out;
            }
        }
        input {
            width: 100%;
            height: 2.5rem;
            border-bottom: 1px solid $grey-1;
        }
        textarea {
            width: 100%;
            min-height: 8rem;
            border-top: 1px solid $grey-1;
            border-bottom: 1px solid $grey-1;
            padding: 0.75rem 0;
            &::placeholder {
                opacity: 1;
            }
        }

        input:focus {
            &::placeholder {
                opacity: 1;
            }
        }
        input[type="radio"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 1px solid $grey-1;
            border-radius: 50%;
            flex-shrink: 1;
            transform: scale(0.9);
            &::before {
                content: "";
                border-radius: 50%;
                transform: translate(-1px, -17px) scale(0);
                transition: transform 0.15s ease-in-out;
                display: block;
                width: 18px;
                height: 18px;
                @media #{$max-xs} {
                    transform: translate(-1px, -15px) scale(0);
                  }
            }
            &:checked::before {
                background: $grey-1;
                border: 1px solid $grey-1;
                transform: translate(-1px, -16px) scale(1);
                width: 18px;
                height: 18px;
                @media #{$max-xs} {
                    transform: translate(-1px, -14px) scale(1);
                  }
            }
        }
    }
    .error-message {
        position: absolute;
        bottom: -1.3rem;
        left: 0;
        font-size: 0.9rem;
        color: #fb6363;
        &.error-top {
            position: relative;
            padding-bottom: 2rem;
        }
        &.textarea {
            top: auto;
            bottom: 0.75rem;
            left: 0.75rem;
        }
    }
}

.form-action {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-top: 4.063rem;
    button {
        // font-size: 1.313rem;
        // color:$white;
        display: flex;
        transition: color 0.3s $cubic;
        cursor: pointer;
        // &:hover {
            // color:$grey-2;
        // }
        .text {
            line-height: 25px;
            padding-right: 1rem;
        }
        svg {
            width: 35px;
            height: 25px;
        }
    }
}
.zitoumeno2-other, .zitoumeno-other {
    transition: opacity .5s $cubic, transform .8s $cubic;
    opacity: 0;
    visibility: hidden;
    transform: translateY(50%);
    &.otherActive {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    } 
}