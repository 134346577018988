@use 'variables' as *;

@for $i from 0 through 12 {
  .col-#{$i} {
    width: calc(  (8.3%*#{$i}) );
    text-align: center;
  }
}
.row {
  display: flex;
  flex-wrap: nowrap;
}
.col:not(:first-child) {
  margin-left: 12px;
}
.col:not(:last-child) {
  margin-right: 12px;
}
