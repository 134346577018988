@import '../../../styles/variables';
@import '../../../styles/mixins';

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: $black;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0.8s $cubic-out;
  display: grid;
  z-index: -1;
  &.isOpened {
    opacity: 1;
    transform: translateY(0);
    z-index: 3;
    .modalInner {
      .top {
        .title {
          text-align: center;
          padding-bottom: 2.75rem;
          font-size: 1.375rem;
          padding-bottom: 2.75rem;
          font-size: 1.375rem;
          transform: translateY(0);
          opacity: 1;
        }
        .svgContainer-dedicated {
          width: 95px;
          height: 111px;
          transform: scale(2);
          transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
          margin-top: 2rem;
          margin-bottom: 2rem;
          svg {
            transform: unset !important;
            height: 100%;
            width: 100%;
          }
        }
        .email {
          transition: all 0.5s ease-out;
          transform: translateY(0);
          opacity: 1;
          a {
            color: #e0455e;
          }
        }
      }
      .back {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .modalInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .top {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 1.375rem;
        padding-bottom: 3.5rem;
        transform: translateY(-50px);
        opacity: 0;
        transition: transform 1s $cubic-out 1s, opacity 1s $cubic-out 0.8s;
        @media #{$max-s} {
          font-size: 1.3rem;
        }
      }
      .svgContainer {
        opacity: 0;
        transition: opacity 1s $cubic-out 0.8s;
      }
      .email {
        font-size: 2.875rem;
        padding-top: 2.5rem;
        transform: translateY(50px);
        opacity: 0;
        transition: transform 1s $cubic-out, opacity 1s $cubic-out 0.8s;
        @media #{$max-s} {
          font-size: 1.125rem;
        }
      }
    }
    .back {
      font-size: 1.125rem;
      transform: translateY(50px);
      opacity: 0;
      padding-bottom: 8rem;
      cursor: pointer;
      transition: transform 1s $cubic-out 1s, opacity 0.8s $cubic-out;
      &:hover {
        opacity: 0.6;
      }
      &::after {
        content: '↑';
        display: inline-block;
        width: 10px;
        height: 100%;
        padding-left: 4px;
        animation: slide-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
      }
    }
  }
}
