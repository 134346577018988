@import "../../../styles/variables";
@keyframes fadeInOut {
  0% {opacity:0;}
  50% {opacity:1;}
  100% {opacity:0;}

}
.loader-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
  display: grid;
  place-content: center;
  background: $white;
  overflow: hidden;
  visibility: visible;
  // transition: visibililty 2s $cubic;
  animation: fadeInOut 2s;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}
.loader {
  // margin: 60px auto;
  // font-size: 10px;
  // position: relative;
  // text-indent: -9999em;
  // border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  // border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  // border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  // border-left: 1.1em solid $grey-1;
  // transform: translateZ(0);
  // animation: load8 1.1s infinite linear;
  // overflow: hidden;
  // padding-top: 50%;
  // transform: translateX(50%)
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}