@import '../../../styles/variables';
@import '../../../styles/mixins';

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(0);
  transition: all 1s $cubic;
  align-items: center;
  display: flex;
  padding-bottom: 2.25rem;
  z-index: 2;
  @media #{$max-l} {
    padding-bottom: 3.5rem;
  }
  @media #{$max-m} {
    padding-bottom: 2.5rem;
  }
  @media #{$max-s} {
    padding-bottom: 1.5rem;
  }
  @media #{$max-xs} {
    padding-bottom: 1rem;
  }
  .brand {
    font-size: 1.375rem;
  }
  .back {
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 0.6;
    }
    &::after {
      content: '↑';
      display: inline-block;
      width: 10px;
      height: 100%;
      padding-left: 4px;
      font-size: 1.5rem;
      animation: slide-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
    }
  }
  .inner {
    justify-content: space-between;
    @media #{$max-s} {
      justify-content: center;
    }
    &.footerIsHidden {
      justify-content: center;
      .footer-items {
        opacity: 0;
        display: none;
      }
    }
    .footer-items {
      align-self: flex-end;
      &.about,
      &.contact {
        @media #{$max-s} {
          display: none;
        }
      }
      .socialNav {
        .item {
          font-size: 0.9rem;
          padding-bottom: 0.3rem;
        }
      }
      &.more {
        transition: transform 0.8s $cubic-out, opacity 0.6s $cubic-out;

        &.about {
          @media #{$max-s} {
            display: none;
          }
        }
        &:hover {
          opacity: 0.6;
        }
        &::after {
          content: '↓';
          display: inline-block;
          width: 10px;
          height: 100%;
          padding-left: 4px;
          font-size: 1.5rem;
          animation: slide-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
        }
      }
      .svgContainer {
        width: 95px;
        height: 111px;
        transform: scale(2);
        cursor: pointer;
        transition: opacity 0.4s $cubic-out;
        &:hover {
          svg path {
            fill: #9f9fa1;
          }
        }
        svg {
          transform: unset !important;
          height: 100%;
          width: 100%;
          path {
            transition: fill 0.5s ease-out;
          }
        }
      }
    }
  }
}
