/**
  Fonts
  ===================================================================
*/


@font-face {
  font-family: 'Visuelt';
  src: url('../assets/fonts/VisueltPro-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Visuelt';
  src: url('../assets/fonts/VisueltPro-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'MCQueen';
  src: url("../assets/fonts/McQueenDisplay-Medium.woff2") format("woff2"),
    url("../assets/fonts/McQueenDisplay-Medium.woff2") format("woff");
}