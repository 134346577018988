@import 'variables';

/**
  Mixins
  ===================================================================
*/

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}