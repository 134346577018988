@import 'variables';

/**
  Typography
  ===================================================================
*/
html {
  font-family: $default-font;
  font-size: 14px;
  color: $grey-1;
  // @media #{$max-s} {
  //   font-size: 15px;
  // }
  // @media #{$max-m} {
  //   font-size: 16px;
  // }
  // scrollbar-width: none;
  box-sizing: border-box;
  // scroll-behavior: smooth;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // scroll-snap-type: y mandatory;
  // overscroll-behavior: none;
  // overflow-x: hidden;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overscroll-behavior: none;

  @media #{$xs} {
    font-size: 15px;
  }
  @media #{$s} {
    font-size: 15px;
  }
  @media #{$m} {
    font-size: 16px;
  }
}
body {
}

a {
  color: $grey-1;
  transition: opacity 0.8s $cubic;
  &:hover {
    opacity: 0.6;
  }
}
