@import 'variables';

@use 'mixins' as *;

#root {
  // min-height: 100vh;
  // height: 100%;
  // overflow-y: hidden;
}

.container {
  max-width: 106rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
  @media #{$max-xl} {
  }
  @media #{$max-m} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media #{$max-s} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media #{$max-xs} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.inner {
  width: 100%;
  max-width: $layout-width;
  margin-left: 10.125rem;
  margin-right: 10.125rem;
  @media #{$max-xl} {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  @media #{$max-l} {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  @media #{$max-m} {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  @media #{$max-s} {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  @media #{$max-xs} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.svg-defs {
  display: none;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
body {
  opacity: 1;
}
.page-exit {
  opacity: 0;
}
.page-enter {
  opacity: 1;
}

.dol {
  animation: dol 0.5s cubic-bezier(0.45, 1.2, 0.55, 1) forwards;
  @keyframes dol {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
